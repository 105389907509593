import React from "react";
import PropTypes from "prop-types";
import styled, { ThemeProvider } from "styled-components";
import deprecatedTheme from "../styling/deprecated_theme";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import baseuiTheme from "../styling/baseui_theme";
import { Client as Styletron } from "styletron-engine-atomic";
import { LoginPage as LoginBackground } from "../login";
import {
  StyledCard,
  StyledHeading,
  StyledParagraphMedium,
} from "../shared/login_and_sign_up/styled";
import ProgramSchoolLogoBlock from "../shared/login_and_sign_up/program_school_logo_block";
import { Button } from "../components/base_ui/inputs/button";
import { KIND } from "baseui/button";
import FormControl from "../components/base_ui/inputs/form_control";
import Input from "../components/base_ui/inputs/input";
import jQuery from "jquery";
import { emailRegex } from "../../utils/string_utils";

const engine = new Styletron();

const InputContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.sizing.scale800};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.sizing.scale800};
  margin-bottom: 0px;

  button {
    margin-top: ${({ theme }) => theme.sizing.scale600};
    margin-bottom: 0px;
  }
`;

class SecondaryEmail extends React.Component {
  static propTypes = {
    programData: PropTypes.object.isRequired,
    addSecondaryEmailPath: PropTypes.string.isRequired,
    csrfToken: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      confirmEmail: "",
      errorEmail: false,
      errorEmailCaption: "",
      errorConfirmEmail: false,
      errorConfirmEmailCaption: "",
      alreadyExistingEmail: false,
    };
  }

  render() {
    return (
      <StyletronProvider value={engine}>
        <BaseProvider theme={baseuiTheme}>
          <ThemeProvider theme={{ ...deprecatedTheme, ...baseuiTheme }}>
            <LoginBackground>
              <StyledCard>
                <div className="secondary-email-flow">
                  <div className="brand-name text-align-center">
                    <ProgramSchoolLogoBlock
                      programLogo={this.props.programData.logo}
                      programName={this.props.programData.title}
                      schoolLogo={this.props.programData.schoolLogo}
                      schoolName={this.props.programData.schoolName}
                    />
                  </div>
                  <StyledHeading data-test="add-secondary-email-title">
                    Add Secondary Email
                  </StyledHeading>
                  <StyledParagraphMedium>
                    Before we create a new account for you, please add a secondary email address.
                    You can use this email to log in to your Mentor Collective account or reset your
                    password even if you can no longer authenticate through your school's portal. If
                    you skip this step, you can only log in through{" "}
                    {this.props.programData.schoolName} for this program.
                  </StyledParagraphMedium>
                  <form name="secondary_email_form">
                    <div className="styled-inputs">
                      <InputContainer>
                        <FormControl
                          label="Email"
                          error={this.state.errorEmail}
                          caption={this.state.errorEmailCaption}
                          errorDataAnalyticsId={
                            this.state.alreadyExistingEmail
                              ? "secondary-email-already-existing-email"
                              : ""
                          }
                        >
                          <Input
                            aria-label="email"
                            name="email"
                            value={this.state.email}
                            placeholder="Enter your email"
                            onChange={(event) => {
                              this.setState({
                                email: event.target.value,
                                errorEmail: false,
                                errorEmailCaption: "",
                                alreadyExistingEmail: false,
                              });
                            }}
                            dataTest={"secondary-email-input"}
                          />
                        </FormControl>
                      </InputContainer>
                      <InputContainer>
                        <FormControl
                          label="Confirm Email"
                          error={this.state.errorConfirmEmail}
                          caption={this.state.errorConfirmEmailCaption}
                        >
                          <Input
                            aria-label="confirm email"
                            name="confirmEmail"
                            value={this.state.confirmEmail}
                            placeholder="Confirm your email"
                            onChange={(event) => {
                              this.setState({
                                confirmEmail: event.target.value,
                                errorConfirmEmail: false,
                                errorConfirmEmailCaption: "",
                              });
                            }}
                            dataTest={"secondary-email-confirm-input"}
                          />
                        </FormControl>
                      </InputContainer>
                    </div>
                    <ButtonContainer>
                      <Button
                        className="continueButton"
                        data-test="secondary-email-continue-button"
                        ariaLabel="continue"
                        data-analytics-id="add-secondary-email-continue"
                        isStretched={true}
                        onClick={this.handleContinue}
                      >
                        Continue
                      </Button>
                      <Button
                        className="skipButton"
                        ariaLabel="skip"
                        isStretched={true}
                        kind={KIND.tertiary}
                        data-analytics-id="add-secondary-email-skip"
                        onClick={this.handleSkip}
                      >
                        Skip
                      </Button>
                    </ButtonContainer>
                  </form>
                </div>
              </StyledCard>
            </LoginBackground>
          </ThemeProvider>
        </BaseProvider>
      </StyletronProvider>
    );
  }

  validateBeforeSubmission() {
    let errorsDetected = false;

    // blank or invalid email format
    if (!this.state.email.match(emailRegex)) {
      this.setState({
        errorEmail: true,
        errorEmailCaption: this.state.email === "" ? "Email is required." : "Invalid email format.",
      });
      errorsDetected = true;
    }

    // confirm email is blank or does not match password
    if (this.state.confirmEmail === "" || this.state.email !== this.state.confirmEmail) {
      this.setState({
        errorConfirmEmail: true,
        errorConfirmEmailCaption:
          this.state.confirmEmail === "" ? "Confirm email is required." : "Emails do not match.",
      });
      errorsDetected = true;
    }

    return !errorsDetected;
  }

  handleContinue = async (event) => {
    event.preventDefault();

    const validationPassed = this.validateBeforeSubmission();
    if (!validationPassed) {
      return;
    }

    try {
      const result = await jQuery.post({
        url: this.props.addSecondaryEmailPath,
        headers: { "X-CSRF-Token": this.props.csrfToken },
        data: {
          program_id: this.props.programData.id,
          email: this.state.email,
        },
      });

      if (result.errors?.length) {
        this.setState({
          errorEmail: true,
          errorEmailCaption: result.errors.join(", "),
          alreadyExistingEmail: result.already_existing_email,
        });
      } else {
        window.location.reload();
      }
    } catch (response) {
      this.setState({
        errorEmail: true,
        errorEmailCaption: "Oops, something went wrong. Please try again.",
      });
    }
  };

  handleSkip = async (event) => {
    event.preventDefault();
    const skipUrl = window.location.href + "?skip_secondary_email=true";
    window.location.assign(skipUrl);
  };
}

export default SecondaryEmail;

import React from "react";
import { render } from "react-dom";
import SecondaryEmail from "../../../components/sign_up/secondary_email";

function init() {
  const componentElements = document.querySelectorAll(
    '[data-react-class="sign_up/secondary_email"]'
  );
  componentElements.forEach((rootElement) => {
    const classString = rootElement.getAttribute("data-react-class");
    const propsJson = rootElement.getAttribute("data-react-props");
    const props = rootElement && JSON.parse(propsJson ?? "");
    if (classString) {
      render(<SecondaryEmail {...props} />, rootElement);
    }
  });
}

if (document.readyState === "complete" || document.readyState === "interactive") {
  // document has at least been parsed
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}
